.filtersContainer {
  display: flex;
}

.radioFilterContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.listItem {
  align-self: center !important;
}

.drawerContainer {
  padding: 20px;
  width: 1024px;
  box-sizing: border-box;
}
