.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.heading {
  color: #595555;
  text-align: center;
}

.appContainer {
  flex-grow: 1;
  padding: 40px;
}
