.actionBar {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.formInput {
  margin: 20px 0;
}

.newRecommendationBtn {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.drawerContainer {
  padding: 20px;
  width: 1024px;
  box-sizing: border-box;
}
