.card {
  margin-bottom: 20px;
}

.cardHeading {
  display: flex;
  gap: 15px;
}
.candidateRow {
  margin: 10px 0;
}

.experience {
  display: flex;
  gap: 10px;
}

.circleIcon {
  font-size: 10px !important;
  margin-right: 10px;
  color: #ccc;
}

.paginationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.drawerContainer {
  padding: 20px;
  width: 1024px;
  box-sizing: border-box;
}

.candidateItemfooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.section {
  margin: 20px 0;
  margin-top: 0px;
}

.profileExperience {
  margin-bottom: 40px;
}

.messageContent {
  margin-top: 20px !important;
  white-space: pre-wrap;
}

.editableForm {
  margin: 20px 0;
}

.flexSection {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}

.sectionHeader {
  min-width: 10%;
}

.section {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}

.profileName {
  display: flex;
  justify-content: space-between;
}
