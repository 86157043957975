.app {
  display: flex;
  height: 100vh;
}
.appBar {
  background-color: #272525 !important;
}

.container {
  margin-top: 64px;
  padding: 20px;
}

.appNav {
  background-color: #272525;
}

.appLogo {
  height: 64px;
  width: 64px;
  margin-right: 10px;
  background-color: #272525;
}

.selectedListItem {
  background-color: #4d4d4dfc;
}

.selectedListItem p {
  color: #fff !important;
}

.listItem {
  justify-content: space-between;
}

.divider {
  border-color: #434141 !important;
  margin-bottom: 10px !important;
}

.heading {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 30px;
  margin-right: 10px;
  background-color: #272525;
}
