.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #272525;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
