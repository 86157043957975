.stepperContainer {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  gap: 30px;
}

.step {
  cursor: pointer;
}
