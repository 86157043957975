.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.heading {
  text-align: center;
  color: #4e4545;
}

.appContainer {
  flex-grow: 1;
  overflow: auto;
  padding: 16px;
}
